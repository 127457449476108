
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { parseTime } from "@/utils";
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { getManageLog, getManageLogComment } from "@/api/request/setting";

//组件
@Component({
  name: "OperationLog",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    row: 20,

    //临时数据
    time: [],

    //时间参数
    endformat: "",
    startformat: "",

    //请求参数
    u_name: "",
    adm_log_memo: "",
    adm_log_model: "",
  };

  // 日志操作类型
  private logTypeList: any = {};

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取操作类型
    this.getOpProps();

    //获取数据
    this.getList();
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.endformat = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.startformat = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.endformat = `${this.listQuery.time[1]}`;
      this.listQuery.startformat = `${this.listQuery.time[0]}`;
    } else {
      this.listQuery.endformat = "";
      this.listQuery.startformat = "";
    }

    //获取数据
    const { data } = await getManageLog(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);

    //增加列表内按钮的loading
    this.list = this.list.map((item) => {
      this.$set(item, "btnLoading", false);
      return item;
    });
  }

  //获取操作类型
  private async getOpProps() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getManageLogComment({});

    //数据赋值
    this.logTypeList = data.list;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //时间处理
  private parseTime(time: any): string {
    return parseTime(time) as string;
  }

  //获取操作类型名称
  private getOpTypeName(key: any): string {
    //数据赋值
    const name = this.logTypeList[key];

    //返回数据
    return name || "未定义";
  }
}
