//本地导入
import request from "../request";

//获取后台操作日志
export const getManageLog = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/log",
  });

//获取后台操作选项
export const getManageLogComment = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/logComment",
  });
